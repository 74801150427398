import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ConfirmButtons = ({
  ticketObj, setCapacity, capacity, handleSubmit, handleClose,
}) => {
  if (ticketObj.persons === 1) {
    if (ticketObj.status.capability > 0) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <Button sx={{ mt: 1 }} variant="contained" onClick={() => handleSubmit()}>Merkitse lippu käytetyksi</Button>
          <Button sx={{ mt: 1 }} variant="containedSecondary" onClick={async () => handleClose()}>Sulje päivittämättä</Button>
        </Box>
      );
    }

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Button sx={{ mt: 1 }} variant="containedAlert" onClick={() => handleSubmit()}>Merkitse lippu käyttämättömäksi</Button>
        <Button sx={{ mt: 1 }} variant="containedSecondary" onClick={async () => handleClose()}>Sulje päivittämättä</Button>
      </Box>
    );
  }

  if (ticketObj.status.capability <= 0) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pt: 2,
        pb: 2,
        border: '2px solid grey',
        borderTop: '0',
        borderRadius: '5px',
        width: '100%',
        maxWidth: 360,
      }}
      >
        <Typography>
          Lipun kapasiteetti (käytetty: {ticketObj.status.used}/{ticketObj.persons})
        </Typography>
        <Typography>
          Vapauta henkilömäärää käyttämättömäksi:
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <Button
            variant="plusMinus"
            onClick={() => capacity < 0 && setCapacity(capacity + 1)}
          >
            -
          </Button>
          <Typography>{-capacity}/{ticketObj.status.used}</Typography>
          <Button
            variant="plusMinus"
            onClick={() => capacity > -ticketObj.status.used
            && setCapacity(capacity - 1)}
          >
            +
          </Button>
        </Box>
        <Button sx={{ mt: 1 }} variant="containedAlert" onClick={() => handleSubmit()}>Kuittaa käyttämättömäksi</Button>
        <Button sx={{ mt: 1 }} variant="containedSecondary" onClick={async () => handleClose()}>Sulje päivittämättä</Button>
      </Box>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      pt: 2,
      pb: 2,
      border: '2px solid grey',
      borderTop: '0',
      borderRadius: '5px',
      width: '100%',
      maxWidth: 360,
    }}
    >
      <Typography>
        Lipun kapasiteetti (käytetty: {ticketObj.status.used}/{ticketObj.persons})
      </Typography>
      <Typography>
        Vielä käytettävissä oleva lipun henkilömäärä:
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Button
          variant="plusMinus"
          onClick={() => capacity > 0 && setCapacity(capacity - 1)}
        >
          -
        </Button>
        <Typography>{capacity}/{ticketObj.status.capability}</Typography>
        <Button
          variant="plusMinus"
          onClick={() => capacity < ticketObj.status.capability
          && setCapacity(capacity + 1)}
        >
          +
        </Button>
      </Box>
      <Button sx={{ mt: 1 }} variant="contained" onClick={() => handleSubmit()}>Kuittaa käytetyksi</Button>
      <Button sx={{ mt: 1 }} variant="containedSecondary" onClick={async () => handleClose()}>Sulje päivittämättä</Button>
    </Box>
  );
};

export default ConfirmButtons;
