import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { getTicketInformation, updateTicketStatus } from '../api/services/TicketAPI';
import ConfirmButtons from '../components/ConfirmButtons';
import { parseTicket } from '../utils/TicketParser';
import { getTemplate } from '../utils/TemplateTicket';
import { useAuth } from '../utils/AuthProvider';

const listStyle = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
  border: '2px solid grey',
  borderRadius: '5px',
  mt: 1,
};

const ListItem = (props) => {
  const {
    sx, f, s,
  } = props;

  return (
    <MuiListItem
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <ListItemText primary={f} sx={{ mr: '10px' }} />
      <ListItemText sx={{ textAlign: 'end' }} primary={s} />
    </MuiListItem>
  );
};

const Confirm = ({ handleClose, data }) => {
  const [capacity, setCapacity] = useState(0);
  const [ticketObj, setTicketObj] = useState(null);
  const parsed = parseTicket(data);
  const auth = useAuth();
  const templateTicket = getTemplate();

  const fetchTicketData = async () => {
    const ticketInformation = await getTicketInformation(auth.user.username, auth.user.password, parsed.id, false, 'FIN');
    if (ticketInformation.data.ticketInformations) {
      toast.success('Lippu luettu!');
      const infObj = ticketInformation.data.ticketInformations.ticketInformation[0];
      setTicketObj(infObj);

      // Laitetaan valmiiksi joko lipun kapasiteetin käyttö tai sen palauttaminen käyttämättömäksi
      if (infObj.status.capability > 0) {
        setCapacity(infObj.status.capability);
      } else if (infObj.persons === 1) {
        setCapacity(-1);
      } else {
        setCapacity(0);
      }

      // TODO: parempi vertailu? id:tä ei pohjalipulla
      // "ole", tää bugittaa muutenkin? .description null
      if (templateTicket?.description !== infObj?.description
          || templateTicket?.timespan !== infObj?.timespan) {
        toast.warning('Pohjaliput tiedot eivät täsmää!', { autoClose: 10000 });
      }
    } else {
      toast.error(`Virhe lippua luettaessa: ${ticketInformation.data.status.description}, virhekoodi: ${ticketInformation.data.status.code}`, { autoClose: 10000 });
      handleClose();
    }
  };

  useEffect(() => {
    fetchTicketData();
  }, []);

  const handleSubmit = async () => {
    if (capacity <= 0) {
      await updateTicketStatus(
        auth.user.username,
        auth.user.password,
        ticketObj.id,
        capacity,
        false,
        'FIN',
      );

      if (capacity < -1) {
        toast.success('Henkilömäärää vapautettu!');
      } else {
        toast.success('Lippu merkitty käyttämättömäksi!');
      }
      handleClose();
    } else {
      await updateTicketStatus(
        auth.user.username,
        auth.user.password,
        ticketObj.id,
        capacity,
        false,
        'FIN',
      );

      toast.success('Lippu merkitty käytetyksi!');
      handleClose();
    }
  };

  if (ticketObj == null) {
    return (
      <div>
        loading
      </div>
    );
  }

  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 4,
      mb: 4,
    }}
    >
      {
        ticketObj.status.capability === 0
        && (
        <Paper sx={{
          textAlign: 'center',
          p: 2,
          pl: 5,
          pr: 5,
          mt: 2,
          bgcolor: 'red',
        }}
        >
          <Typography>Lippu on jo käytetty!</Typography>
        </Paper>
        )
      }
      <List sx={listStyle}>
        <ListItem f="Lipun numero:" s={ticketObj.id} />
        <Divider />
        <ListItem f="Tuote:" s={ticketObj.description} />
        <Divider />
        <ListItem f="Päivämäärä:" s={ticketObj.timespan.substring(0, ticketObj.timespan.indexOf('-')).substring(0, 10)} />
        <Divider />
        <ListItem f="Ajankohta:" s={ticketObj.timespan.substring(0, ticketObj.timespan.indexOf('-')).substring(10)} />
        <Divider />
        <ListItem f="Lipputyyppi:" s={ticketObj.name} />
        <Divider />
        <ListItem f="Henkilömäärä:" s={ticketObj.persons} />
      </List>
      <ConfirmButtons
        ticketObj={ticketObj}
        setCapacity={setCapacity}
        capacity={capacity}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default Confirm;
