import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import { parseTicket } from '../utils/TicketParser';
import { getTicketInformation } from '../api/services/TicketAPI';
import { useAuth } from '../utils/AuthProvider';
import { setTemplate } from '../utils/TemplateTicket';

const listStyle = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
  border: '2px solid grey',
  borderRadius: '5px',
  mt: 3,
};

const ListItem = (props) => {
  const {
    sx, f, s,
  } = props;

  return (
    <MuiListItem
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <ListItemText primary={f} />
      <ListItemText sx={{ textAlign: 'end' }} primary={s} />
    </MuiListItem>
  );
};

const ConfirmTemplate = ({ handleClose, data }) => {
  const [ticketObj, setTicketObj] = useState(null);
  const parsed = parseTicket(data);
  const auth = useAuth();
  const navigate = useNavigate();

  const fetchTicketData = async () => {
    const ticketInformation = await getTicketInformation(auth.user.username, auth.user.password, parsed.id, false, 'FIN');
    if (ticketInformation.data.status.code === 0) {
      setTicketObj(ticketInformation.data.ticketInformations.ticketInformation[0]);
      toast.success('Pohjalippu luettu!');
    } else {
      toast.error(`Virhe pohjalippua luettaessa: ${ticketInformation.data.status.description}, virhekoodi: ${ticketInformation.data.status.code}`, { autoClose: 10000 });
      handleClose();
    }
  };

  useEffect(() => {
    fetchTicketData();
  }, []);

  const handleSubmit = () => {
    const obj = {
      description: ticketObj.description,
      timespan: ticketObj.timespan,
    };

    setTemplate(obj);
    toast.success('Pohjalippu asetettu!');
    navigate('/main');
  };

  if (ticketObj == null) {
    return (
      <div>
        loading
      </div>
    );
  }

  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      mt: 8,
    }}
    >
      <List sx={listStyle}>
        <ListItem f="Tuote:" s={ticketObj.description} />
        <Divider />
        <ListItem f="Päivämäärä:" s={ticketObj.timespan} />
        <Divider />
        <ListItem f="Ajankohta:" s={ticketObj.timespan} />
      </List>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pt: 2,
        pb: 2,
        border: '2px solid grey',
        borderTop: '0',
        borderRadius: '5px',
        width: '100%',
        maxWidth: 360,
      }}
      >
        <Button variant="contained" onClick={() => handleSubmit()}>Päivitä pohjalippu</Button>
        <Button sx={{ mt: 1 }} variant="containedSecondary" onClick={async () => handleClose()}>Sulje tallentamatta</Button>
      </Box>
    </Container>
  );
};

export default ConfirmTemplate;
