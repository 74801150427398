/* eslint-disable object-shorthand */
import axios from 'axios';

const APIURL = 'https://travelnet.sportum.com/webservice/json/MobileTicket';

async function SoapRequest(xmls) {
  const response = await axios.post(
    'https://travelnet.demo.sportum.com/webservice/services/MobileTicket.MobileTicketHttpsSoap11Endpoint/',
    xmls,
    {
      headers: {
        'Content-Type': 'text/xml',
      },
    },
  );

  return response;
}

const SoapTest = () => {
  SoapRequest('asdasdasd');
};

const getTicketInformation = async (userId, key, ticketId, listUpdates, language) => {
  const response = await axios.post(
    APIURL,
    {
      action: 'ticket_information',
      user: userId,
      key: key,
      ticketId: ticketId,
      listUpdates: listUpdates,
      language: language,
    },
  );

  return response;
};

const updateTicketStatus = async (userId, key, ticketId, amount, listUpdates, language) => {
  const response = await axios.post(
    APIURL,
    {
      action: 'update_ticket',
      user: userId,
      key: key,
      ticketId: ticketId,
      amount: amount,
      listUpdates: listUpdates,
      language: language,
    },
  );

  return response;
};

const checkAuthentication = async (user, key) => {
  const response = await axios.post(
    APIURL,
    {
      action: 'check_authentication',
      user: user,
      key: key,
    },
  );

  return response;
};

const getStatusInformation = async (userId, key, ticketId, language) => {
  const response = await axios.post(
    APIURL,
    {
      action: 'status_information',
      user: userId,
      key: key,
      ticketId: ticketId,
      language: language,
    },
  );

  return response;
};

export {
  SoapTest, getTicketInformation, updateTicketStatus, checkAuthentication, getStatusInformation,
};
