import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Image from 'mui-image';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { useAuth } from '../utils/AuthProvider';
import { LoginTextField as TextField } from '../utils/Theme';

const SignIn = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/main';

  useEffect(() => {
    if (auth.user) {
      navigate(from, { replace: true });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    await auth.login(data);
    navigate(from, { replace: true });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Image duration={0} src="/sportum_travelnet.png" alt="logo" />
        <Avatar
          sx={{
            margin: 1,
            mt: 2,
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1">
          Kirjaudu sisään
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Käyttäjänimi"
          name="username"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          label="Salasana"
          name="password"
          type="password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
        >
          Kirjaudu sisään
        </Button>
      </Box>
    </Container>
  );
};

export default SignIn;
