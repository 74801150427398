import { createTheme, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'containedSecondary' },
          style: {
            backgroundColor: 'lightGrey',
            color: 'white',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          },
        },
        {
          props: { variant: 'containedAlert' },
          style: {
            backgroundColor: '#ffc107',
            color: 'white',
            '&:hover': {
              backgroundColor: '#ffecb3',
            },
          },
        },
        {
          props: { variant: 'plusMinus' },
          style: {
            backgroundColor: 'white',
            color: 'black',
          },
        },
      ],
      styleOverrides: {
        root: {
          color: 'white',
        },
        contained: {
          backgroundColor: 'darkGreen',
          '&:hover': {
            backgroundColor: '#68b36b',
          },
        },
      },
    },
  },
});

const LoginTextField = styled(TextField)({
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  '& .MuiOutlinedInput-input': {
    color: 'black',
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 0px #000',
      '-webkit-text-fill-color': '#000',
    },
  },
});

export { theme, LoginTextField };
