import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';

import Confirm from './Confirm';
import ConfirmTemplate from './ConfirmTemplate';

const ReadPage = ({ route }) => {
  const [data, setData] = useState('');
  const [drawer, setDrawer] = useState(false);

  const handleClose = () => {
    setDrawer(false);
    setData('');
  };

  return (
    <Container maxWidth="false" disableGutters sx={{ mt: 2 }}>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        videoStyle={{ width: '100%' }}
        onResult={(result) => {
          if (data === '' && result && result !== undefined && result !== 'undefined' && drawer === false) {
            setDrawer(true);
            setData(result.text);
          }
        }}
      />
      <Drawer
        anchor="right"
        open={drawer}
        onClose={() => {
          setData('');
          setDrawer(false);
        }}
      >
        {
          route === 'read'
          && <Confirm handleClose={handleClose} data={data} />
        }
        {
          route === 'template'
          && <ConfirmTemplate handleClose={handleClose} data={data} />
        }
      </Drawer>
    </Container>
  );
};

export default ReadPage;
