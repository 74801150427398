import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { checkAuthentication } from '../api/services/TicketAPI';

const AuthContext = React.createContext();

const getStorage = () => {
  try {
    const username = window.sessionStorage.getItem('ticketInspectorUsername');
    const password = window.sessionStorage.getItem('ticketInspectorKey');

    if (username && password) {
      return ({
        username,
        password,
      });
    }

    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(getStorage());

  const login = async (data) => {
    const username = data.get('username');
    const password = data.get('password');
    const res = await checkAuthentication(username, password);
    if (res.data.status.code === 0) {
      setUser({ username, password });
      // Jostain syystä tallentaa ilmeisesti väärille avaimille mutta toimii silti?
      window.sessionStorage.setItem('ticketInspectorUsername', username);
      window.sessionStorage.setItem('ticketInspectorKey', password);
    } else {
      toast.error('Kirjautuminen epäonnistui!');
    }
  };

  const logout = () => {
    window.sessionStorage.removeItem('ticketInspectorUsername');
    window.sessionStorage.removeItem('ticketInspectorKey');
    setUser(null);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { user, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth, AuthProvider };
