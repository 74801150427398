const parseTicket = (data) => {
  const dataObj = {
    id: data.substring(0, 14),
    date: data.substring(14, 20).replace(/^0+/, ''),
    time: data.substring(20, 24).replace(/^0+/, ''),
    persons: data.substring(24, 28).replace(/^0+/, ''),
    name: data.substring(28, 46).replace(/^0+/, ''),
    service: data.substring(44, 62).replace(/^0+/, ''),
  };

  return dataObj;
};

export { parseTicket };
