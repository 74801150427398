const setTemplate = (template) => {
  try {
    window.localStorage.setItem('ticketInspectorTemplate', JSON.stringify(template));
  } catch (e) {
    console.error(e);
  }
};

const getTemplate = () => {
  try {
    const template = window.localStorage.getItem('ticketInspectorTemplate');
    // eslint-disable-next-line prefer-template
    return JSON.parse(template);
  } catch (e) {
    console.error(e);
    return null;
  }
};

const delTemplate = () => {
  try {
    window.localStorage.removeItem('ticketInspectorTemplate');
  } catch (e) {
    console.error(e);
  }
};

export { setTemplate, getTemplate, delTemplate };
