import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Image from 'mui-image';

const Lander = () => {
  return (
    <Container
      /* maxWidth="false"
      disableGutters
      sx={{
        height: '100vh',
      }} */
      component="main"
      maxWidth="xs"
    >

      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Image src="/sportum_travelnet.png" alt="logo" />
        <Button component={Link} to="/login" variant="contained" sx={{ mt: 4 }}>
          Kirjautumiseen
        </Button>
      </Box>
    </Container>
  );
};

export default Lander;
