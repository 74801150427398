import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';

import Login from './pages/Login';
import Lander from './pages/Lander';
import Main from './pages/Main';
import Read from './pages/Read';
import Confirm from './pages/Confirm';
import TopBar from './components/TopBar';
import Copyright from './components/Copyright';

import { ProtectedRoute } from './utils/ProtectedRoute';
import { AuthProvider } from './utils/AuthProvider';
import { theme } from './utils/Theme';

const App = () => {
  // const { height, width } = useWindowDimensions();
  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="false"
        disableGutters
        sx={{
          height: '100%',
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <AuthProvider>
          <TopBar />
          <Routes>
            <Route path="/" element={<Lander />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/main"
              element={(
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/read"
              element={(
                <ProtectedRoute>
                  <Read route="read" />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/confirm"
              element={(
                <ProtectedRoute>
                  <Confirm />
                </ProtectedRoute>
              )}
            />
            <Route
              path="/template"
              element={(
                <ProtectedRoute>
                  <Read route="template" />
                </ProtectedRoute>
              )}
            />
          </Routes>
        </AuthProvider>
        <Container sx={{ align: 'center' }}>
          <Copyright />
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default App;
