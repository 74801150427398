import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { getTemplate } from '../utils/TemplateTicket';

// TODO: refactor to theme?
const BorderItem = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        border: '1px solid',
        bgcolor: 'white',
        color: 'black',
        borderRadius: 2,
        borderColor: 'grey.800',
        p: 1,
        m: 0.1,
        ...sx,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
      {...other}
    />
  );
};

const Main = () => {
  const template = getTemplate();
  return (
    <Container>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button variant="contained" size="large" component={Link} to="/read">
          <Typography variant="body2" sx={{ mr: 2 }}>Lue lippu</Typography>
          <CameraAltIcon />
        </Button>
      </Box>
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={template == null ? { mb: 1, color: 'grey' } : { mb: 1 }}>
          Pohjalipun tiedot:
        </Typography>
        <Box sx={{ display: 'grid', gidTemplateRows: 'repeat(1, 1fr)', width: '100%' }}>
          <BorderItem sx={template == null ? { color: 'grey', borderColor: 'grey' } : {}}>
            <Typography mr={5}>Tuote: </Typography>
            <Typography>{ template?.description }</Typography>
          </BorderItem>
          <BorderItem sx={template == null ? { color: 'grey', borderColor: 'grey' } : {}}>
            <Typography>Päivämäärä:</Typography>
            <Typography>{ template?.timespan.substring(0, template?.timespan.indexOf('-')).substring(0, 10) }</Typography>
          </BorderItem>
          <BorderItem sx={template == null ? { color: 'grey', borderColor: 'grey' } : {}}>
            <Typography>Ajankohta:</Typography>
            <Typography>{template?.timespan.substring(0, template?.timespan.indexOf('-')).substring(10)}</Typography>
          </BorderItem>
        </Box>
      </Box>
      {
        template == null
        && (
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button variant="contained" size="large" component={Link} to="/template">
              <Typography variant="body2">Aseta pohjalippu</Typography>
            </Button>
          </Box>
        )
      }
    </Container>
  );
};

export default Main;
