import React, { useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';

import { useAuth } from '../utils/AuthProvider';

const TopBar = () => {
  const auth = useAuth();
  const [settingsMenu, setSettingsMenu] = useState(null);

  const handleMenu = (e) => {
    setSettingsMenu(e.currentTarget);
  };

  const handleClose = () => {
    setSettingsMenu(null);
  };

  const handleLogout = () => {
    setSettingsMenu(null);
    auth.logout();
  };

  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
        }}
      >
        <Toolbar>
          <Box
            sx={{
              mr: 2,
              flexGrow: 1,
            }}
          >
            <Link
              color="DarkGreen"
              underline="none"
              variant="h6"
              component={RouterLink}
              to="/main"
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'monospace',
                  fontWeight: '700',
                  letterSpacing: '.2rem',
                }}
              >
                TicketInspector
              </Typography>
            </Link>
          </Box>

          {
            auth.user
            && (
              <IconButton size="large" arial-label="settings" onClick={handleMenu}>
                <SettingsIcon />
              </IconButton>
            )
          }
          {
            auth.user
            && (
            <Menu
              id="topbar-menu"
              anchorEl={settingsMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(settingsMenu)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Button component={RouterLink} variant="contained" to="/template">
                  Aseta pohjalippu
                </Button>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Button variant="contained">
                  Kirjaudu ulos
                </Button>
              </MenuItem>
            </Menu>
            )
          }

        </Toolbar>

      </AppBar>
    </Box>
  );
};

export default TopBar;
