/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="grey"
      align="center"
      sx={{
        // position: 'absolute',
        bottom: 0,
        width: '100%',
        mb: 5,
        mt: 5,
      }}
      {...props}
    >
      {'Copyright ©'}
      <Link color="inherit" href="https://www.sportum.fi/">
        Sportum Oy
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
